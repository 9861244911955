<template>
  <v-container>
    <v-tabs
      class="ma-4x"
      v-model="tab"
      background-color="white"
      color="primary"
      dark
    >
      <v-tab class="text--secondary">Nuevo Usuario</v-tab>
      <v-tab class="text--secondary">Directores</v-tab>
      <v-tab class="text--secondary">Gerentes</v-tab>
      <v-tab class="text--secondary">API Keys</v-tab>
    </v-tabs>
    <!-- <div v-for="user in allManagers" :key="user.username">
      <AbstractUserProfile :isAdmin="true" :username="user.username" :userType="user.userType" />
    </div> -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <AbstractUserCard
          class="mt-5"
          :isUserInfo="false"
          :isDirector="false"
          :establishmentsDataTableHeaders="establishmentsDataTableHeaders"
          :establishments="establishments"
          @createNewManagerUser="createNewManagerUser"
          @alert="alertNotification"
          @createNewDirectorUser="createNewDirectorUser"
          @refreshUsers="refreshUsers"
        />
      </v-tab-item>
      <v-tab-item>
        <p
          v-if="isDirectorsEmpty"
          class="button text-center text--disabled pa-5 mt-5 text--lighten-5"
        >
          Todavia no hay usuarios directores
        </p>
        <AbstractUserCard
          v-else
          class="mt-5"
          v-for="director in allDirectors"
          :user="director"
          :key="director.id"
          :userId="director.id"
          :usernameInfo="director.username"
          :isDirector="true"
          :isUserInfo="true"
          @saveNewUsername="saveNewUsername"
          @refreshUsers="refreshUsers"
          @alert="alertNotification"
          @deleteUser="deleteUser"
          @saveNewPassword="saveNewPassword"
        />
      </v-tab-item>
      <v-tab-item>
        <p
          v-if="isManagersEmpty"
          class="button text-center text--disabled pa-5 mt-5 text--lighten-5"
        >
          Todavia no hay usuarios gerentes
        </p>
        <AbstractUserCard
          v-else
          class="mt-5"
          v-for="manager in parsedManagers"
          :key="manager.id"
          :isDirector="false"
          :userId="manager.id"
          :user="manager"
          :usernameInfo="manager.username"
          :userEstablishments="manager.establishments"
          :isUserInfo="true"
          :establishmentsDataTableHeaders="establishmentsDataTableHeaders"
          :establishments="establishments"
          @saveNewUsername="saveNewUsername"
          @removeReleatedEstablishments="removeReleatedEstablishments"
          @addEstablishmentsRelations="addEstablishmentsRelations"
          @refreshUsers="refreshUsers"
          @alert="alertNotification"
          @deleteUser="deleteUser"
          @saveNewPassword="saveNewPassword"
        />
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-title class="d-flex justify-space-between align-center">
            <span>API Keys</span>
            <v-btn color="primary" @click="showCreateDialog = true">
              Create New API Key
            </v-btn>
          </v-card-title>

          <v-data-table
            :headers="apiKeyHeaders"
            :items="apiKeysWithLogs"
            :loading="isPageLoading"
          >
            <template v-slot:item.key="{ item }">
              <div class="d-flex align-center">
                {{ truncateKey(item.key) }}
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      class="ml-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="copyToClipboard(item.key)"
                    >
                      <v-icon small>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>Copy full API key</span>
                </v-tooltip>
              </div>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn small color="error" @click="deleteApiKey(item.id)">
                Delete
              </v-btn>
            </template>

            <template v-slot:item.expirationDate="{ item }">
              {{ formatDate(item.expirationDate) }}
            </template>

            <template v-slot:item.createdAt="{ item }">
              {{ formatDate(item.createdAt) }}
            </template>
          </v-data-table>
        </v-card>

        <!-- Create API Key Dialog -->
        <v-dialog v-model="showCreateDialog" max-width="500px">
          <v-card>
            <v-card-title>Create New API Key</v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid">
                <v-text-field
                  v-model="newApiKey.key"
                  label="Key"
                  readonly
                  :rules="[(v) => !!v || 'Key is required']"
                  required
                ></v-text-field>

                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="newApiKey.expirationDate"
                      label="Expiration Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[(v) => !!v || 'Expiration date is required']"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="newApiKey.expirationDate"
                    @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="showCreateDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="createNewApiKey"
                :disabled="!valid"
              >
                Create
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import AbstractUserCard from "../components/Abstract/Users/AbstractUserCard.vue";
import {
  deleteManagerEstablishmentRelations,
  deleteDirectorUser,
  deleteManagerUser,
  postManagerRelations,
  postDirectorUser,
  postManagerUser,
  putDirectorUser,
  putManagerUser,
  putUserPassword,
} from "../managrx/AutAndAuthServices";

export default {
  name: "AdminPanel",
  components: { AbstractUserCard },
  mounted() {
    this.$store.dispatch("getAllUsers");
    this.$store.dispatch("getEstablishments");
    this.$store.dispatch("getApiKeys");
  },
  computed: {
    ...mapGetters([
      "allManagers",
      "allDirectors",
      "establishments",
      "apiKeys",
      "isPageLoading",
    ]),
    apiKeysWithLogs() {
      console.log("apiKeys in computed:", this.apiKeys);
      if (this.apiKeys && this.apiKeys.length > 0) {
        console.log("Sample apiKey:", this.apiKeys[0]);
        console.log("Sample expirationDate:", this.apiKeys[0].expirationDate);
        console.log("Sample created_at:", this.apiKeys[0].created_at);
      }
      return this.apiKeys;
    },
    parsedManagers() {
      if (this.allManagers) {
        return this.allManagers.map((el) => {
          return {
            ...el,
            establishments: this.establishments.filter((item) =>
              el.establishmentsIds.includes(item.id)
            ),
          };
        });
      } else {
        return {};
      }
    },
    isManagersEmpty() {
      return this.allManagers?.length ? false : true;
    },
    isDirectorsEmpty() {
      return this.allDirectors?.length ? false : true;
    },
    engagementDataTableHeaders() {
      return [
        { name: "Campaña", value: "campaignName" },
        { name: "Version", value: "campaignVersion" },
        { name: "Establecimiento", value: "establishmentName" },
      ].map((i) => {
        return {
          text: i.name.toLocaleUpperCase(),
          value: i.value,
        };
      });
    },
    apiKeyHeaders() {
      return [
        { text: "ID", value: "id" },
        { text: "Key", value: "key" },
        { text: "Created By ID", value: "createdBy" },
        { text: "Expiration Date", value: "expirationDate" },
        { text: "Created At", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
  },
  methods: {
    async saveNewPassword(payload) {
      this.$store.dispatch("setPageLoadingStatus", true);
      let result = await putUserPassword(payload.userId, payload.password);
      if (!result.success) {
        this.$store.dispatch("setNewNotification", result);
      }
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    alertNotification(alert) {
      console.log("this is emit", alert);
      this.$store.dispatch("setNewNotification", alert);
    },
    async deleteUser(payload) {
      let isDirector = payload?.isDirector;
      let result;
      if (isDirector) {
        result = await deleteDirectorUser(payload.id);
      } else {
        result = await deleteManagerUser(payload.id);
      }
      if (result.success) {
        this.refreshUsers();
      } else {
        this.$store.dispatch("setNewNotification", result);
      }
    },
    async saveNewUsername(payload) {
      let isDirector = payload?.isDirector;
      let data = { id: payload?.user?.id, username: payload?.username };
      let result;
      if (isDirector) {
        result = await putDirectorUser(data);
      } else {
        result = await putManagerUser(data);
      }
      if (result.success) {
        this.refreshUsers();
      } else {
        this.$store.dispatch("setNewNotification", result);
      }
    },
    async removeReleatedEstablishments(payload) {
      let relationsIdsToRemove = payload.relationsIdsToRemove;

      let resultArray = await deleteManagerEstablishmentRelations(
        relationsIdsToRemove
      );

      this.checkResultArrayAndRefreshUsers(resultArray);
    },
    async addEstablishmentsRelations(payload) {
      let manager = payload.user;
      let establishmentsIds = payload.establishmentsIds;
      let resultArray = await postManagerRelations(
        manager?.id,
        establishmentsIds
      );
      this.checkResultArrayAndRefreshUsers(resultArray);
    },
    async createNewDirectorUser(userInfo) {
      let result = await postDirectorUser(userInfo);
      if (result?.success) {
        this.refreshUsers();
      } else {
        this.$store.dispatch("setNewNotification", result);
      }
    },
    async createNewManagerUser(managerInfo) {
      let result = await postManagerUser(managerInfo.userInfo);

      if (result?.success) {
        let resultArray = await postManagerRelations(
          result?.data?.id,
          managerInfo?.establishmentsIds
        );

        this.checkResultArrayAndRefreshUsers(resultArray);
      } else {
        this.$store.dispatch("setNewNotification", result);
      }
    },
    checkResultArrayAndRefreshUsers(resultArray) {
      if (!resultArray.success) {
        let data = resultArray.data;
        for (const result of data) {
          if (!result.success) {
            this.$store.dispatch("setNewNotification", result);
          }
        }
      } else {
        this.refreshUsers();
      }
    },
    refreshUsers() {
      /* Force update to rerender component, computed arrays can't re render */
      this.$store.dispatch("getAllUsers");
    },
    generateRandomKey() {
      // Generate 32 random bytes and convert to base64
      const array = new Uint8Array(32);
      window.crypto.getRandomValues(array);
      const key = btoa(String.fromCharCode.apply(null, array))
        .replace(/\+/g, "-") // Convert + to -
        .replace(/\//g, "_") // Convert / to _
        .replace(/=+$/, ""); // Remove ending =
      return key;
    },
    async createNewApiKey() {
      if (this.$refs.form.validate()) {
        const payload = {
          ...this.newApiKey,
          expirationDate: new Date(this.newApiKey.expirationDate).toISOString(),
        };

        await this.$store.dispatch("createApiKey", payload);
        this.showCreateDialog = false;
        this.newApiKey = {
          key: "",
          expirationDate: null,
        };
      }
    },
    async deleteApiKey(id) {
      if (confirm("Are you sure you want to delete this API key?")) {
        await this.$store.dispatch("deleteApiKey", id);
      }
    },
    formatDate(dateString) {
      if (!dateString) return "";
      return dateString.split("T")[0];
    },
    truncateKey(key) {
      if (!key) return "";
      // Show first 6 and last 4 characters
      return `${key.substring(0, 6)}...${key.substring(key.length - 4)}`;
    },
    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.$store.dispatch("setNewNotification", {
          data: {
            type: "success",
            text: "API key copied to clipboard",
          },
        });
      } catch (err) {
        this.$store.dispatch("setNewNotification", {
          data: {
            type: "error",
            text: "Failed to copy API key",
          },
        });
      }
    },
  },
  data() {
    return {
      tab: null,
      showCreateDialog: null,
      dateMenu: false,
      valid: false,
      newApiKey: {
        key: "",
        expirationDate: null,
      },
      establishmentsDataTableHeaders: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Hectáreas Físicas",
          value: "hectares",
        },
        {
          text: "Region",
          value: "regionName",
        },
        {
          text: "Creado",
          value: "createdAt",
        },
      ],
    };
  },
  watch: {
    showCreateDialog(val) {
      if (val) {
        this.newApiKey.key = this.generateRandomKey();
        // Set expiration date to 3 months from now
        const threeMonthsFromNow = new Date();
        threeMonthsFromNow.setMonth(threeMonthsFromNow.getMonth() + 3);
        // Format as YYYY-MM-DD for v-date-picker
        this.newApiKey.expirationDate = threeMonthsFromNow
          .toISOString()
          .split("T")[0];
      }
    },
  },
};
</script>

<style></style>
